import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MUILink, Tooltip, Typography } from '@mui/material';
import { formatDistance } from 'date-fns';
import { useTheme } from '@mui/system';
import config from '@configFile';
import { formatDateTime } from '@utils/index';

const PosterDetails = ({ postDate }: { postDate?: string | null }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const additional: React.CSSProperties = {};

  if (isDarkMode) {
    additional.color = '#c7b2f5';
  }

  if (!postDate) {
    return (
      <Typography variant="caption">
        {`Posted by `}
        <MUILink
          component={Link}
          to={config.admin.authorLink}
          sx={{
            fontSize: '0.75rem',
            ...additional
          }}
          rel="author" // Indicates the link points to the author's page
          aria-label={`Author: ${config.admin.firstName} ${config.admin.lastName} ${config.admin.title}`} // Improves accessibility
          onClick={() => {
            // smooth scroll to top
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          {`${config.admin.firstName} ${config.admin.lastName} | ${config.admin.title}`}
        </MUILink>
      </Typography>
    );
  }

  return (
    <>
      <Tooltip title={`${formatDateTime(new Date(postDate))}`}>
        <span>
          Posted{' '}
          <time dateTime={new Date(postDate).toISOString()}>
            {formatDistance(new Date(postDate), new Date(), {
              addSuffix: true
            })}
          </time>{' '}
          by{' '}
        </span>
      </Tooltip>
      <MUILink
        component={Link}
        to={config.admin.authorLink}
        sx={{
          fontSize: '0.75rem',
          ...additional
        }}
        onClick={() => {
          // smooth scroll to top
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        rel="author" // Indicates the link points to the author's page
        aria-label={`Author: ${config.admin.firstName} | ${config.admin.title}`} // Improves accessibility
      >
        {`${config.admin.firstName} | ${config.admin.title}`}
      </MUILink>
    </>
  );
};

export default PosterDetails;
