import React from 'react';
import CommonAccordion from '@components/CommonAccordion';
import { useGetCouponDealsByCode } from '@hooks/useDeals';
import config from '@configFile';

const CouponDeals = ({
  currentASIN,
  promoCode,
  defaultExpanded = true,
  titleComponent = 'h3'
}: {
  currentASIN: string;
  promoCode: string;
  defaultExpanded?: boolean;
  titleComponent?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}) => {
  const { data: deals, isLoading } = useGetCouponDealsByCode(promoCode);

  if (isLoading || !deals) {
    return null;
  }

  return (
    <CommonAccordion
      title="You could alternatively use this coupon on..."
      deals={deals}
      currentASIN={currentASIN}
      defaultExpanded={defaultExpanded}
      affiliateTag={config.AFFILIATE_TAGS.JD_DEAL_COUPON}
      titleComponent={titleComponent}
    />
  );
};

export default CouponDeals;
