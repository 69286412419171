import React from 'react';
import { Box } from '@mui/material';
import config from '@configFile';
import { useGetProductImages } from '@hooks/useProductImages';
import { getProductLink } from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';

const TARGET_HEIGHT = 390;

const scaleImage = (image: { imageWidth: number; imageHeight: number }) => {
  const aspectRatio = image.imageWidth / image.imageHeight;
  const newWidth = aspectRatio * TARGET_HEIGHT;
  return {
    ...image,
    width: newWidth,
    height: TARGET_HEIGHT
  };
};

const ProductImage = ({
  ASIN,
  tag = config.AFFILIATE_TAGS.JD_IMAGES
}: {
  ASIN: string;
  tag?: string;
}) => {
  const { data: productImages } = useGetProductImages(ASIN);

  if (!ASIN || !productImages || !productImages.length) {
    return null;
  }

  const productImage = productImages?.[0];

  const scaledImage = scaleImage(productImage);

  return (
    <Box display="flex" component="section">
      <MonetizingLink
        target="_blank"
        variant="body1"
        tag={tag}
        clickType="product-image-link"
        href={getProductLink(ASIN, tag)}
        sx={{
          margin: '0 auto'
        }}
      >
        <Box
          component="img"
          src={`${productImage.image}`}
          alt={productImage.caption}
          loading="lazy"
          width={scaledImage.width}
          height={scaledImage.height}
          sx={{
            maxWidth: '100%',
            margin: '12px auto 0px auto'
          }}
        />
      </MonetizingLink>
    </Box>
  );
};

export default ProductImage;
